















































































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import { MercadoPay } from '@/service/MercadoPay/MercadoPay';
import { RegisterPaymentModel } from '@/model/RegisterPaymentModel';
import { Container } from 'typescript-ioc';
import { GiftModel } from '@/model/GiftModel';

@Component({
    components:{
    },
})

export default class Payment extends Vue{

    @Prop()
    public giftListCart!: GiftModel[];
    @Prop()
    public totalAmount!: number;

    public dialog: boolean = false;

    //Formúlario de preenchimento
    public primeiroNome: string = '';
    public segundoNome: string = '';
    public cpf: string = '';
    public email: string = '';
    public ddd: string = '';
    public phone: string = '';
    public message: string = '';

    public corSnackbar:string = 'green';
    public textSnackbar: string='Copiado';

    public qrCodeImage: string = '';
    public qrCodePayment: string = '';

    //Booleano para habilitar alerta
    public alertaPreenchimento: boolean = false;
    //Booleano para habilitar snackbar
    public snackbar: boolean = false;

    //Booleano para habilitar formulario
    public habilitarFormulario: boolean = true;
    //Booleano para habilitar loading de aguarde
    public habilitarLoading: boolean = false;
    //Booleano para habilitar qrcode
    public habilitarQrCode: boolean = false;
    //Booleano para habilitar confirmação de pagamento
    public habilitarConfirmacaoPagamento: boolean = false;

    public minutes: string = "";
    public seconds: string = "";

    private get MercadoPay(): MercadoPay {
        return Container.get(MercadoPay) as MercadoPay;
    }

    public get timeCount(): Date {
        if (this.habilitarQrCode) {
            const date = new Date();
            date.setMinutes(date.getMinutes() + 30);
            return date;
        }
        return new Date();
    }

    public mounted() {
        this.startCountdown();
    }

    public async confirmarPagamento(){
        this.alertaPreenchimento = false;
        if(!!this.primeiroNome && !!this.segundoNome && !!this.cpf && !!this.cpf && !!this.ddd && !!this.phone){
            this.alertaPreenchimento = false;
            this.habilitarFormulario = false;
            this.habilitarLoading = true;
            const request = new RegisterPaymentModel();
            request.firstName = this.primeiroNome;
            request.lastName = this.segundoNome;
            request.email = this.email;
            request.areaCode = this.ddd;
            request.number = this.phone;
            request.cpf = this.cpf;
            request.message = this.message;
            request.totalPayment = parseFloat(this.totalAmount.toFixed(2));
            this.giftListCart.forEach(element => {
                request.gifts!.push({ giftId: element.id, amount: element.amount, product: element.product})
            });

            const resultPayment = await this.MercadoPay.getPayment(request);

            if(resultPayment.success){
                this.qrCodePayment = resultPayment.qrCodeKey;
                this.qrCodeImage = resultPayment.qrCodeImage;

                this.habilitarFormulario = false;
                this.habilitarLoading = false;
                this.habilitarQrCode = true;

                let flagPayment = "pending";

                while (flagPayment === "pending") {
                    try {
                        const resultChecking = await this.MercadoPay.checkingPayment(resultPayment.idTransaction);
                        if (resultChecking.status === 'approved') {
                            flagPayment = 'approved';
                            this.habilitarQrCode = false;
                            this.habilitarConfirmacaoPagamento = true;
                            this.limparCamposFormulario();
                        } else {
                            console.log('Aguardando valor desejado...');
                        }
                    } catch (error) {
                        console.error('Erro ao chamar a API:', error);
                    }

                    if(flagPayment === "pending"){
                        await new Promise(resolve => setTimeout(resolve, 15000));
                    }
                }
            }

        }
        else{
            this.alertaPreenchimento = true;
        }
        this.limparCamposFormulario();
    }

    public copyToClipboard() {
    navigator.clipboard.writeText(this.qrCodePayment)
        .then(() => {
            this.snackbar = true;
        })
        .catch(err => {
            this.snackbar = false;
        });
    }

    private limparCamposFormulario(){
        this.primeiroNome = '';
        this.segundoNome = '';
        this.cpf = '';
        this.ddd = '';
        this.phone = '';
        this.email = '';
        this.message = '';
        this.giftListCart = [];
    }

    public fecharFormulario(){
        this.dialog = false;
        this.limparCamposFormulario();
    }

    private limparCamposPagamento(){
        this.qrCodePayment = '';
        this.qrCodeImage = '';
    }

    public fecharPagamento(){
        this.dialog = false;
        this.limparCamposFormulario();
        this.limparCamposPagamento();
        this.habilitarFormulario = true;
        this.habilitarQrCode = false;
    }

    public async fecharConfirmacaoPagamento(){
        this.dialog = false;
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.$emit('confirmarPagamento');
        this.limparCamposFormulario();
        this.limparCamposPagamento();
        this.habilitarFormulario = true;
        this.habilitarQrCode = false;
        this.habilitarConfirmacaoPagamento = false;
    }

    public formatMoney(numero: any) {
        const numeroFormatado = numero.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return numeroFormatado
    }

    public startCountdown() {
        const countdown = () => {
            const now = new Date();
            const timeLeft = this.timeCount.getTime() - now.getTime();


            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

            this.minutes = String(minutes).padStart(2, '0');
            this.seconds = String(seconds).padStart(2, '0');
        };

        countdown();
        setInterval(countdown, 1000);
    }
}
